import React from "react"
import { Link } from "gatsby"
import "./reset.css"
import "./layout.css"

class Layout extends React.Component {
  render() {
    const { title, children } = this.props
    let header

    header = (
      <h1>
        <Link to={`/`}>
          {title}
        </Link>
      </h1>
    )

    return (
      <div>
        <header className='header'>{header}</header>
        <main>{children}</main>
      </div>
    )
  }
}

export default Layout
